@import 'src/scss/variables';

.wrapper {
	background: white;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	text-align: center;
	padding: 10px;
	width: 80%;
	max-width: 600px;
	margin: 0 auto;
	margin-top: 15%;
	.notFound {
        font-size: 50px;
		font-family: Roboto;
	}
	.desc {
		font-family: Roboto;
		font-size: 20px;
		padding-bottom: 20px;
    }
    @media (max-width: $mobile) {
        width: 95%;
        box-shadow: none;
    }
}
